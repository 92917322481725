var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("獎勵應用中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("點數管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold"},[_vm._v("點數管理")]),_c('div',{staticClass:"\n          col-12 col-xl-8\n          d-flex\n          flex-xl-row\n          align-items-start align-items-xl-center\n        "},[_c('div',{staticClass:"flex-shrink-0"},[(_vm.checkPermission([_vm.consts.POINT_LOGS_LIST_VIEW]))?_c('b-button',{staticClass:"mb-2 mb-xl-0 mr-2",attrs:{"variant":"link","to":{
              name: 'PointLogList',
            }}},[_c('i',{staticClass:"fa fa-folder-open"}),_vm._v("點數異動紀錄 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.POINT_LIST_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3",attrs:{"variant":"primary","to":{ name: 'PointCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增點數 ")]):_vm._e()],1),_c('b-form-select',{staticClass:"mr-3",attrs:{"options":_vm.merchants,"disabled":_vm.merchants.length == 2},on:{"change":_vm.handlePageReset},model:{value:(_vm.query.merchant_id),callback:function ($$v) {_vm.$set(_vm.query, "merchant_id", $$v)},expression:"query.merchant_id"}}),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"搜尋會員名稱、手機、外部編碼"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch()}},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.handleSearch()}}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.points,"fields":_vm.fields,"busy":_vm.isLoading,"no-local-sorting":true},on:{"sort-changed":_vm.handleSort},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-primary","to":{
            name: 'PointView',
            params: {
              point_id: data.item.id,
              merchant_id: _vm.query.merchant_id,
            },
          }}},[_c('span',{staticClass:"mdi mdi-eye"}),_vm._v(" 查看 ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"inverse-warning","to":{
            name: 'PointEdit',
            params: {
              point_id: data.item.id,
              merchant_id: _vm.query.merchant_id,
            },
          }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯 ")]),_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deletePoint(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除 ")])]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.total,"perPage":_vm.query.per_page,"perPageOptions":_vm.perPageOption},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }