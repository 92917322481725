import https from "./https"
import store from "@/store"

const pointApi = {
  getPoints(merchantId, params) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/merchants/${merchantId}/points`, {params})
  },
  getPoint(merchantId, pointId) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/merchants/${merchantId}/points/${pointId}`)
  },
  updatePoint(merchantId, params) {
    const organization = store.state.general.organization;
    return https.put(`admin/organizations/${organization.id}/merchants/${merchantId}/points/${params.id}`, params)
  },
  removePoint(merchantId, params) {
    const organization = store.state.general.organization;
    const url = `admin/organizations/${organization.id}/merchants/${merchantId}/points/${params.id}`;
    return https.delete(url)
  },
  storePoint(merchantId,params) {
    const organization = store.state.general.organization
    const url = `admin/organizations/${organization.id}/merchants/${merchantId}/points`

    return https.post(url, params)
  }
}

export default pointApi
