// 注意，route.push 會導致頁面重新渲染（component 會重新載入），如果不想要重載，改用 updateUrlWithoutReload
export function updateUrl(query, store, router) {
  const filteredQuery = Object.keys(query).reduce((acc, key) => {
    if (query[key] !== null && query[key] !== undefined && query[key] !== "") {
      acc[key] = query[key];
    }
    return acc;
  }, {});
  const currentHash = router.currentRoute.hash;
  store.commit("route/SET_ROUTE_QUERY", filteredQuery);
  store.commit("route/SET_ROUTE_PARAMS", router.currentRoute.params);
  router
    .push({ path: "", hash: currentHash, query: filteredQuery })
    .catch((err) => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
}

export function updateUrlWithoutReload(query, store, route) {
  const filteredQuery = Object.keys(query).reduce((acc, key) => {
    if (query[key] !== null && query[key] !== undefined && query[key] !== "") {
      acc[key] = query[key];
    }
    return acc;
  }, {});
  store.commit("route/SET_ROUTE_QUERY", filteredQuery);
  store.commit("route/SET_ROUTE_PARAMS", route.params);

  const basePath = route.path || window.location.pathname;

  const normalizedPath = basePath.replace(/\/+$/, '');

  const queryString = Object.keys(filteredQuery)
    .map(key => {
      if (Array.isArray(filteredQuery[key])) {
        return filteredQuery[key]
          .map(value => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(value)
          })
          .join('&')
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(filteredQuery[key])
    })
    .join('&');

  const newUrl = normalizedPath + (queryString ? '?' + queryString : '');

  history.pushState({}, null, newUrl);
}

export function updateQueryFromRoute(route, store, currentQuery) {
  const updatedQuery = {
    ...currentQuery,
    ...route.query,
  };

  updatedQuery.page = parseInt(updatedQuery.page, 10) || 1;

  if (store && typeof store.commit === "function") {
    store.commit("route/SET_ROUTE_QUERY", updatedQuery);
    store.commit("route/SET_ROUTE_PARAMS", route.params);
  } else {
    console.error("Invalid store object provided");
  }

  return { ...updatedQuery };
}
